import React from 'react'
import './PageSpace.css'

function PageSpace() {
  return (
    <>
      <div className='PageSpace'/>
    </>

  )
}

export default PageSpace;